import { useMemo, useRef, useState } from 'react';

import {
	arrow,
	autoPlacement,
	autoUpdate,
	offset,
	shift,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useRole,
} from '@floating-ui/react';

import type { TooltipOptions } from '../types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useTooltip({
	initialOpen = false,
	open: controlledOpen,
	onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const arrowRef = useRef(null);

	const data = useFloating({
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(5),
			autoPlacement(),
			shift({ padding: 5 }),
			arrow({
				element: arrowRef,
			}),
		],
	});

	const { context } = data;

	const hover = useHover(context);
	// we don't want to open the tooltip on focus. instead, we only close the tooltip if it is open and loses focus
	const focus = useFocus(context, { enabled: context.open });
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: 'tooltip' });

	const interactions = useInteractions([hover, focus, dismiss, role]);

	return useMemo(
		() => ({
			open,
			setOpen,
			arrowRef,
			...interactions,
			...data,
		}),
		[open, setOpen, interactions, data, arrowRef],
	);
}
