import type { ComponentProps, ForwardedRef, HTMLProps } from 'react';

import { useMergeRefs } from '@floating-ui/react';

import { forwardRef } from '@change-corgi/core/react/core';
import type { Box } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';

import { useTooltipContext } from '../hooks/context';

export const TooltipTrigger = forwardRef(function TooltipTrigger(
	{ children, ...props }: ComponentProps<typeof Box>,
	propRef: ForwardedRef<HTMLButtonElement>,
) {
	const context = useTooltipContext();
	const ref = useMergeRefs([context?.refs.setReference, propRef]);

	return (
		<button
			type="button"
			ref={ref}
			sx={{ ...buttonResetStyles, lineHeight: 0, color: 'neutral-grey700' }}
			{...context?.getReferenceProps(props as HTMLProps<HTMLElement>)}
		>
			{children}
		</button>
	);
});
