import type { ComponentProps, JSX } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconInfo, iconInfoOutline } from '@change-corgi/design-system/icons';
import type { Box } from '@change-corgi/design-system/layout';

import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip';

import { useInfoTooltip } from './hooks/useInfoTooltip';

export function InfoTooltip({ children, ...props }: ComponentProps<typeof Box>): JSX.Element {
	const { translate } = useI18n();
	const { data, actions } = useInfoTooltip();
	const icon = data.isOpen ? iconInfo : iconInfoOutline;

	return (
		<Tooltip open={data.isOpen} onOpenChange={actions.setIsOpen}>
			<TooltipTrigger
				aria-label={translate('corgi.components.tooltips.info_tooltip.a11y.label')}
				aria-expanded={data.isOpen}
				onClick={actions.toggleTooltip}
				{...props}
			>
				<Icon icon={icon} color="currentColor" size={18} m={3} />
			</TooltipTrigger>
			<TooltipContent data-qa="tooltip-help-text" sx={{ zIndex: 5001 }}>
				{children}
			</TooltipContent>
		</Tooltip>
	);
}
