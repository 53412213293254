import type { ComponentProps, HTMLProps, JSX } from 'react';

import { FloatingArrow, FloatingPortal } from '@floating-ui/react';

import { Box } from '@change-corgi/design-system/layout';
import { useColor } from '@change-corgi/design-system/theme';

import { useTooltipContext } from '../hooks/context';

type TooltipContentProps = {
	colorScheme?: 'light' | 'dark';
};

export function TooltipContent({
	colorScheme = 'dark',
	children,
	...props
}: ComponentProps<typeof Box> & TooltipContentProps): JSX.Element | null {
	const context = useTooltipContext();
	const backgroundColor = colorScheme === 'dark' ? 'primary-black' : 'background';
	const textColor = colorScheme === 'dark' ? 'typography-lightPrimary' : 'typography-primary';
	const arrowColor = useColor(backgroundColor);

	if (!context.open) return null;

	return (
		<FloatingPortal>
			<Box
				ref={context.refs.setFloating}
				sx={{
					...context.floatingStyles,
					minWidth: 'min-content',
					maxWidth: '400px',
					p: 16,
					borderRadius: 12,
					backgroundColor,
					color: textColor,
				}}
				{...context.getFloatingProps(props as HTMLProps<HTMLElement>)}
				aria-live="polite"
			>
				<FloatingArrow ref={context.arrowRef} context={context.context} fill={arrowColor} />
				{children}
			</Box>
		</FloatingPortal>
	);
}
