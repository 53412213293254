import { createMandatoryContext } from '@change-corgi/core/react/context';

import type { useTooltip } from './useTooltip';

type TooltipContextProps = ReturnType<typeof useTooltip>;

export const {
	Context: TooltipContext,
	Provider: TooltipContextProvider,
	useContext: useTooltipContext,
} = createMandatoryContext<TooltipContextProps, TooltipContextProps>(undefined, {
	name: 'TooltipContext',
	processProviderProps: (props) => props,
});
