import type { JSX, PropsWithChildren } from 'react';

import { TooltipContext } from './hooks/context';
import { useTooltip } from './hooks/useTooltip';
import type { TooltipOptions } from './types';

export function Tooltip({ children, ...options }: PropsWithChildren<TooltipOptions>): JSX.Element {
	// This can accept any props as options, e.g. `placement`, or other positioning options.
	const tooltip = useTooltip(options);
	return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}
