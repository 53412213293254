import { useState } from 'react';

type UseInfoTooltipReturn = ModelHookResult<
	{ isOpen: boolean },
	{ setIsOpen: (open: boolean) => void; toggleTooltip: () => void }
>;
export function useInfoTooltip(): UseInfoTooltipReturn {
	const [isOpen, setIsOpen] = useState(false);

	// This is an enhancement for mobile devices allowing them to see the tooltip
	// on "press" of the `TooltipTrigger` since there is no `hover` event.
	const toggleTooltip = () => {
		setIsOpen(!isOpen);
	};

	return { data: { isOpen }, actions: { setIsOpen, toggleTooltip } };
}
